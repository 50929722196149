import React, { useEffect, useState } from "react";
import "./ProjectDetails.scss";
import useCurrentPage from "../../../hooks/useCurrentPage";
import { Label } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import PageLoader from "../../../assets/loaders/PageLoading";
import moment from "moment";
import IconButton from "../../../assets/elements/IconButton";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import folder from "../../../assets/images/icons/folder.svg";
import export1 from "../../../assets/images/icons/export1.svg";
import options from "../../../assets/images/icons/options.svg";
import { Dropdown } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Chart from "../../echarts";
import useConfig from "../../../hooks/useConfig";
import ProjectsDropdown from "./ProjectDropdown";
import ProjectExportsDropdown from "./ProjectExportsDropdown";
import { ButtonLoader } from "../../../assets/loaders";

export default function AddProject() {
  const { updateCurrentPage } = useCurrentPage();
  const [fileData, setFileData] = useState(null);
  const [addDetailsTabs, setAddDetailsTabs] = useState("project-info");
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const {
    getProjectDetails,
    exportProjectCTR,
    documentStatsByProjectId,
    getProjectHourSummaryForCharts,
    getProjectCostSummaryForCharts,
    getProjectResourceSummaryForCharts,
  } = MainServices();
  const config = useConfig();
  const [project, setProject] = useState();
  const { grantPermission } = PermissionFunctions();
  const location = useLocation();
  const projectIdd = new URLSearchParams(location.search).get("projectId");
  const [projectId, setProjectId] = useState(projectIdd);
  const [projectStats, setProjectStats] = useState([]);
  const [resourceSummaryForCharts, setResourceSummaryForCharts] = useState();
  const [exportLoading, setExportLoading] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);

  const [hourSummaryForCharts, setHourSummaryForCharts] = useState();
  const [costSummaryForCharts, setCostSummaryForCharts] = useState();

  useEffect(() => {
    updateCurrentPage({
      title: "Project",
      subtitle: "Project Details",
    });
    // eslint-disable-next-line
  }, []);

  //functions
  const onTabChange = (type) => {
    if (type === "project-info") {
      setAddDetailsTabs("project-info");
    } else {
      setAddDetailsTabs("high-level-scope");
    }
  };

  useEffect(() => {
    if (projectId) getProjectDetailsPage();
    ProjectStats();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getProjectResourceSummaryForChartsByProjectId();

    getProjectHourSummaryForChartsByProjectId();
    getProjectCostSummaryForChartsByProjectId();
  }, []);

  const getProjectResourceSummaryForChartsByProjectId = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getProjectResourceSummaryForCharts(details, projectId)])
      .then((res) => {
        let resData = res.length > 0 ? res[0].data : [];
        let resourceData = resData?.data;
        resourceData = resourceData.map((resource, index) => {
          return {
            ...resource,
            sNo: index + 1,
          };
        });

        setResourceSummaryForCharts(resourceData);
        setPageLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setPageLoading(false);
      });
  };

  const getProjectHourSummaryForChartsByProjectId = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getProjectHourSummaryForCharts(details, projectId)])
      .then((res) => {
        let resData = res.length > 0 ? res[0].data : [];
        let hourData = resData?.data;

        hourData = hourData.map((hour, index) => {
          return {
            ...hour,
            sNo: index + 1,
          };
        });

        setHourSummaryForCharts(hourData);
        setPageLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setPageLoading(false);
      });
  };

  const getProjectCostSummaryForChartsByProjectId = async () => {
    const details = {};
    await axios
      .all([getProjectCostSummaryForCharts(details, projectId)])
      .then((res) => {
        let resData = res.length > 0 ? res[0].data : [];
        let hourData = resData?.data;

        hourData = hourData.map((hour, index) => {
          return {
            ...hour,
            sNo: index + 1,
          };
        });

        setCostSummaryForCharts(hourData);
      })
      .catch((res) => console.log(res));
  };

  const getProjectDetailsPage = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getProjectDetails(details, projectId)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : {};
            setProject(resData);

            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Projects details");
      });
  };


  const handleExportClick = async () => {
    setExportLoading(true);
    const details = {
      projectId,
    };
    await axios
      .all([exportProjectCTR(details)])
      .then((response) => {
        if (response.length > 0) {
          const {
            file: { filePath },
          } = response[0].data;
          if (filePath) {
            downloadFile(filePath);
            setExportLoading(false);
          } else {
            console.error("File path not found in response.");
            setExportLoading(false);
          }
        } else {
          console.error("File path not found in response.");
          setExportLoading(false);
        }
      })
      .catch((error) => {
        setExportLoading(false);
        console.error("Error fetching file:", error);
      });
  };
  const downloadFile = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "project_ctr.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  const ProjectStats = async () => {
    const details = {};
    await axios
      .all([documentStatsByProjectId(details, projectId)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0]?.data?.data || [];

          setProjectStats(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const prepareProjectStatsForCharts = (originalData) => {
    let data = [];
    data =
      originalData &&
      originalData.length > 0 &&
      originalData.map((each) => {
        return {
          ...each,
          XAxisVariable: each.originalStatus,
          count: each.statusCount,
        };
      });
    return data;
  };

  const prepareHourSummaryStatsForCharts = (originalData) => {
    let data = [];
    data =
      originalData &&
      originalData.length > 0 &&
      originalData.map((each) => {
        return {
          ...each,
          XAxisVariable: each.discipline,
          count: each.totalHours,
        };
      });
    return data;
  };

  const prepareCostSummaryStatsForCharts = (originalData) => {
    let data = [];
    data =
      originalData &&
      originalData.length > 0 &&
      originalData.map((each) => {
        return {
          ...each,
          XAxisVariable: each.discipline,
          count: each.total,
        };
      });
    return data;
  };
  const prepareResourceSummaryStatsForCharts = (originalData) => {
    let data = [];
    data =
      originalData &&
      originalData.length > 0 &&
      originalData.map((each) => {
        return {
          ...each,
          XAxisVariable: each.discipline,
          count: each.total,
        };
      });
    return data;
  };

  return (
    <>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <div className="back-btn-function">
              <img src={ArrowBack} alt="" onClick={() => navigate(-1)} />
            </div>
            <div className="d-flex gap-3 align-items-center">
              {/* {grantPermission("Project_read") && (
                <IconButton
                  // iconType="add"
                  title="Summary"
                  onClick={() => navigate({ pathname: "/project-management/projects/project-summary", search: `?projectId=${projectId}` })}
                />
              )} */}
              {/* {progressLoading ? ( <ButtonLoader color = "black" /> ) : ""} */}
              {/* {grantPermission("Project_read") && (
                <div
                  // iconType="add"
                  title="Export File"
                  onClick={handleExportClick}
                  className="folder-pic"
                >
                  {exportLoading ? (
                    <ButtonLoader />
                  ) : (
                    <img
                      src={export1}
                      alt=""
                      style={{ width: "30px", maxWidth: "30px", height: "25px" }}
                    />
                  )}
                </div>
              )} */}
              <ProjectExportsDropdown
                projectId={project?.publicId}
                projectType={project?.projectType}
                page="details"
              />
              {grantPermission("Project_read") && (
                <div
                  // iconType="add"
                  title="Project Folder"
                  onClick={() =>
                    navigate({
                      pathname: "/documents/repository",
                      search: `?projectId=${projectId}&initial=true`,
                    })
                  }
                  className="folder-pic"
                >
                  <img src={folder} alt=""  style={{ width: "35px", height: "25px" }}/>
                </div>
              )}
              <ProjectsDropdown
                projectId={project?.publicId}
                projectType={project?.projectType}
                page="details"
                setProgressLoading={setProgressLoading}
              />
            </div>
          </div>

          <div className="mt-3">
            <Chart
              name="Documents Status"
              originalData={prepareProjectStatsForCharts(projectStats)}
              fields={["Original Status"]} //array's 0th position is always the variable on X-axis
            />
          </div>
          <div className="mt-3">
            <Chart
              name="Hour Summary"
              originalData={prepareHourSummaryStatsForCharts(
                hourSummaryForCharts
              )}
              fields={["Discipline", "Fields"]}
            />
          </div>
          <div className="mt-3">
            <Chart
              name="Cost Summary"
              originalData={prepareCostSummaryStatsForCharts(
                costSummaryForCharts
              )}
              fields={["Discipline", "Fields"]}
            />
          </div>
          <div className="mt-3">
            <Chart
              name="Resource Summary"
              originalData={prepareResourceSummaryStatsForCharts(
                resourceSummaryForCharts
              )}
              fields={["Discipline", "Fields"]}
            />
          </div>

          <div className="details-project-main">
            <div className="pre-heading">
              <div class="row align-items-center">
                <div class="col">
                  <h1 class="add-project-card-header-main mb-1">
                    Project Details
                  </h1>
                </div>
                {/* <div class="col">
                  <p class="m-0 add-project-card-para-main">
                    Project Code: <span>123454</span>
                  </p>
                </div> */}
              </div>
            </div>

            <div className="project-details-page-main">
              <div className="project-details-tab-main">
                <div
                  className={`add-project-tabs ${
                    addDetailsTabs === "project-info" ? "active" : ""
                  }`}
                  onClick={() => onTabChange("project-info")}
                >
                  <p className="m-0">Project Info</p>
                </div>
                {/* <div
                  className={`add-project-tabs ${
                    addDetailsTabs === "high-level-scope" ? "active" : ""
                  }`}
                >
                  <p
                    className="m-0"
                    onClick={() => onTabChange("high-level-scope")}
                  >
                    High Level Scope Quantitites
                  </p>
                </div> */}
              </div>
              <div className="product-details-data-main mt-3">
                {addDetailsTabs === "project-info" && project && (
                  <>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Project Name</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project.name}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>PDO PE Name/Ref Indicator</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project.pdoPeName}</p>
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Estimate/CTR Code</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project.ctrCode}</p>
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>CTR Revision Number</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project.ctrRevisionNo}</p>
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>WBS Number</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project.wbsNo}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Project Type</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project.projectType}</p>
                      </div>
                    </div>
                    {project.projectType === "standard" && (
                      <div className="row align-items-center form-group mb-3">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                          <Label>Project Sub Type</Label>
                        </div>
                        <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                          <p>{project.projectSubType}</p>
                        </div>
                      </div>
                    )}
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Ramz ProjectId</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project.ramzProjectId}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Project Engineer</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project?.employee?.empName}</p>
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Engineering Scope</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <p>{project.engineeringScope}</p>
                      </div>
                    </div>
                    {project.projectType === "non standard" && (
                      <>
                        <div className="row align-items-center form-group mb-3">
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <Label>Date (Estimate Request)</Label>
                          </div>
                          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                            <p>
                              {moment(project.estimateRequest).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="row align-items-center form-group mb-3">
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <Label>Date (Estimate Submission)</Label>
                          </div>
                          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                            <p>
                              {moment(
                                project.initialEngineeringSubmission
                              ).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>

                        <div className="row align-items-center form-group mb-3">
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <Label>Date (Approved Submission)</Label>
                          </div>
                          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                            <p>
                              {moment(
                                project.approvedEngineeringSubmission
                              ).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>

                        <div className="row align-items-center form-group mb-3">
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <Label>Date (Engineering PO)</Label>
                          </div>
                          <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                            <p>
                              {moment(project.engineeringPo).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {/* {addDetailsTabs === "high-level-scope" && project && (
                  <>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Process Equipment</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>{project.pdoPeName}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Unique Equipment</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>{project.mechanicalUniqueEquipments}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Concrete</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>{project.concrete}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Steel</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>{project.steel}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>I/o List</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>2 NOS</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Electrical Installations</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>{project.electricalInstallation}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Pipe (LM)</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>{project.pipeLm}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Pipe (Tone)</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>{project.pipeTonne}</p>
                      </div>
                    </div>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Mechanical Unique Equipment's</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4 col-lg-7">
                        <p>{project.mechanicalUniqueEquipments}</p>
                      </div>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
