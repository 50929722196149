import "./Header.scss";
import React, { useEffect, useRef, useState } from "react";
import useCurrentPage from "../../hooks/useCurrentPage";
import { Dropdown } from "react-bootstrap";
import { notificationicon } from "../../assets/images/icons/icons";
import headerprofileicon from "../../assets/images/icons/header-profile.svg";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import MainServices from "../../services/MainServices";
import axios from "axios";
import SimpleBar from "simplebar-react";
import Close from "../../assets/images/icons/cross.svg";
import menuIcon from "../../assets/images/icons/menuIcon.svg";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import useConfig from "../../hooks/useConfig";

//images
export default function Header({ setSidebarToggle }) {
  const { currentPage } = useCurrentPage();
  const [isNotificationsOpen, setNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [readNotificationsList, setReadNotificationsList] = useState([]);
  const [unreadNotificationsList, setUnreadNotificationsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { getUserNotifications, updateNotificationReadStatus } = MainServices();
  const { authFiles,authUrl } = useConfig();
  const toggleSideBar = () => {
    setSidebarToggle((oldValue) => !oldValue);
  };

  const onLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const onNotification = () => {
    setNotificationOpen(!isNotificationsOpen);
  };
  const notificationInterval = useRef(null);

  useEffect(() => {
    notificationInterval.current = setInterval(() => {
      getNotifications();
    }, 5000);
    return () => {
      clearInterval(notificationInterval.current);
    };
  }, []);

  //api
  const getNotifications = async () => {
    const details = {};
    await axios
      .all([getUserNotifications(details, user.user_id)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let notifications = resData.data ? resData.data : [];
            let unreadNotificationsList = [];
            let readNotificationsList = [];
            notifications.map((each) => {
              each?.readStatus === "notRead"
                ? unreadNotificationsList.push(each)
                : readNotificationsList.push(each);
            });
            setTotalCount(unreadNotificationsList.length);
            setNotifications(notifications);
            setReadNotificationsList(readNotificationsList);
            setUnreadNotificationsList(unreadNotificationsList);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Permissions");
      });
  };
  const updateNotification = async (noti) => {
    const details = {
      readStatus: "read",
    };
    await axios
      .all([
        updateNotificationReadStatus(
          details,
          noti.publicId,
          noti.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getNotifications();
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Permissions");
      });
  };

  const onNotificationClick = (noti) => {
    if (noti.notificationType === "Document") {
      navigate({
        pathname: "/documents/document-details",
        search: `?documentId=${noti.notificationId}`,
      });
    }
    if (noti.notificationType === "Project") {
      navigate({
        pathname: "project-management/projects/project-preview",
        search: `?projectId=${noti.notificationId}`,
      });
    }
    if (noti.notificationType === "Document Type") {
      navigate({
        pathname: "/masters/doctypes",
      });
    }
    if (noti.notificationType === "Document Category") {
      navigate({
        pathname: "/masters/document-category",
      });
    }
    updateNotification(noti);
    onNotification();
  };

  return (
    <div className="header-main">
      <div className="nk-header is-light">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="d-flex justify-content-between align-items-center pe-3">
              <div className="nk-menu-trigger d-xl-none ms-n1">
                <div
                  onClick={toggleSideBar}
                  className="nk-nav-toggle nk-quick-nav-icon"
                  data-target="sidebarMenu"
                  style={{ cursor: "pointer" }}
                  data-tooltip-id="ToggleTooltip"
                  data-tooltip-content="View Menu"
                >
                  <img
                    src={menuIcon}
                    alt="Menu icon"
                    width={20}
                    height={20}
                  />
                  <Tooltip
                    id="ToggleTooltip"
                    place="top"
                    effect="solid"
                  />
                </div>
              </div>
              <div>
                <p className="m-0">
                  <b>{currentPage?.title} Management</b>{" "}
                  {currentPage.subtitle ? "/ " : ""}
                  {currentPage?.subtitle}
                </p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div
                  className="notification-icon-main"
                  onClick={onNotification}
                  style={{ cursor: "pointer" }}
                >
                  <div>{notificationicon}</div>
                  {totalCount ? (
                    <div className="position-absolute notification-count">
                      {totalCount}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <Dropdown className="dropdown-main">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="dropdown-content-main"
                  >
                    <div className="d-flex align-items-center">
                      <div className="header-profile-icon-main">
                        <img
                          src={
                            user.profilePhoto
                              ? authFiles + user.profilePhoto
                              : headerprofileicon
                          }
                          width={36}
                          height={36}
                          style={{borderRadius:"50%"}}
                          alt="profile icon"
                        />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <p className="m-0">{user?.name}</p>
                        <p className="m-0">{user?.email}</p>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        window.open(`${authUrl}profile?user_id=${user.user_id}`, "_self"
                        )
                      }
                    >
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onLogout}>Sign Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div
          class={`toggle-slide-right ${
            isNotificationsOpen ? "slide-right-open" : "slide-right-close"
          }`}
          id="mobile-menu"
          style={{
            overflowY: "scroll",
            position: "fixed",
            width: "450px",
          }}
        >
          <div className="notifications">
            <div className="notifications-head d-flex justify-content-between align-items-center">
              <h6>
                Notifications{" "}
                {/* {totalCount > 0 && (
                  <>
                    <span className="notification-count">
                      {totalCount ? totalCount : "0"}
                    </span>
                  </>
                )} */}
              </h6>
              <div
                onClick={() => onNotification("")}
                className="notification-close mx-3"
              >
                <img src={Close} alt="list-icon" />
              </div>
            </div>
            <SimpleBar className="notifications-content">
              <div className="notifications-header">
                <h6>New Notifications</h6>
              </div>
              {unreadNotificationsList.map((_) => (
                <div
                  className="each-notification"
                  onClick={() => onNotificationClick(_)}
                >
                  <p className="notification-title">{_.notificationType}</p>
                  <p className="notification-info">{_.message}</p>
                  <p className="notification-time">
                    {moment(_.createdAt).fromNow()}
                  </p>
                </div>
              ))}
              <div className="notifications-header">
                <h6>Old Notifications</h6>
              </div>
              {readNotificationsList.map((_) => (
                <div
                  className="each-notification"
                  onClick={() => onNotificationClick(_)}
                >
                  <p className="notification-title">{_.notificationType}</p>
                  <p className="notification-info">{_.message}</p>
                  <p className="notification-time">
                    {moment(_.createdAt).fromNow()}
                  </p>
                </div>
              ))}
              {/* <hr className=" divider" />
              <div>
                <ul className=" notification-list">
                  <li className="list-sub-header">NEW</li>
                  <hr className=" divider" />
                  {unreadNotificationsList &&
                  unreadNotificationsList.length > 0 ? (
                    <>
                      {unreadNotificationsList &&
                        unreadNotificationsList.length > 0 &&
                        unreadNotificationsList.map((eachNotification) => {
                          return (
                            <>
                              <li
                                className="notification-list-li-open "
                                // onClick={() => {
                                //   handleChangeNotificationDetail(
                                //     eachNotification
                                //   );
                                //   navigate({
                                //     pathname:
                                //       user?.role === "supplier"
                                //         ? "/chat"
                                //         : "/settings",
                                //     search: `?supplierId=${eachNotification?.notificationId}&status=Notifications`,
                                //   });
                                //   onNotification();
                                // }}
                              >
                                <div className=" notification-list-item ">
                                  <h6 className="list-item-h6">
                                    {eachNotification.notificationType &&
                                      eachNotification.notificationType}
                                    <span className=" normal-text">
                                      &nbsp; {eachNotification.message}
                                    </span>
                                  </h6>
                                  {eachNotification.createdAt &&
                                    eachNotification.createdAt && (
                                      <span className="list-item-timeline">
                                        <i class="fas fa-clock"></i> &nbsp;
                                        {moment(
                                          eachNotification.createdAt
                                        ).format("MMMM Do YYYY, h:mm:ss a")}
                                      </span>
                                    )}
                                </div>
                              </li>
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <li className="notification-list-li ">
                      <div className=" notification-list-item ">
                        <h6 className="list-item-h6">
                         No Notifications
                        </h6>
                      </div>
                    </li>
                  )}
                </ul>
                <ul className=" notification-list">
                  <hr className=" divider" />
                  <li className="list-sub-header">BEFORE THAT</li>
                  <hr className=" divider" />
                  {readNotificationsList && readNotificationsList.length > 0 ? (
                    <>
                      {readNotificationsList &&
                        readNotificationsList.length > 0 &&
                        readNotificationsList.map((eachNotification) => {
                          return (
                            <>
                              <li
                                // onClick={() => {
                                //   handleChangeNotificationDetail(
                                //     eachNotification
                                //   );
                                //   navigate({
                                //     pathname:
                                //       user?.role === "supplier"
                                //         ? "/chat"
                                //         : "/settings",
                                //     search: `?supplierId=${eachNotification?.notificationId}&status=Notifications`,
                                //   });
                                //   notificationToggleModal();
                                // }}
                                className="notification-list-li "
                              >
                                <div className=" notification-list-item ">
                                  <h6 className="list-item-h6">
                                    {eachNotification.notificationType &&
                                      eachNotification.notificationType}
                                    <span className=" normal-text">
                                      &nbsp; {eachNotification.message}
                                    </span>
                                  </h6>
                                  {eachNotification.createdAt &&
                                    eachNotification.createdAt && (
                                      <span className="list-item-timeline">
                                        <i class="fas fa-clock"></i> &nbsp;
                                        {moment(
                                          eachNotification.createdAt
                                        ).format("MMMM Do YYYY, h:mm:ss a")}
                                      </span>
                                    )}
                                </div>
                              </li>
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <li className="notification-list-li ">
                      <div className=" notification-list-item ">
                        <h6 className="list-item-h6">
                          No Notifications 
                        </h6>
                      </div>
                    </li>
                  )}
                </ul>
              </div> */}
            </SimpleBar>
          </div>
        </div>
      </div>
    </div>
  );
}
