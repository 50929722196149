import React, { useEffect, useState } from "react";
import './pendingReview.scss'
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import MainServices from "../../../services/MainServices";
import axios from "axios";
import { Table } from "reactstrap";
import Pagination from "../../../assets/elements/CustomPagination";
import useCurrentPage from "../../../hooks/useCurrentPage";
import {
    paramsToFilters,
    paramsToFiltersObj,
    filtersToUri,
    filtersToUrlParams,
} from "../../../assets/functions/filters";
import { InputGroup, Button, Form, Modal } from "react-bootstrap";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import editicon from "../../../assets/images/icons/edit.svg";
import { Formik } from "formik";
import { icons, squareBackIcon } from "../../../assets/images/icons/icons";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { PrimaryInput } from "../../../assets/FormElements";
import useAuth from "../../../hooks/useAuth";

export default function IDCPending() {
    const { getDocumentWorkflowsByUser, getProjectMasters, getEmployeesMaster } = MainServices()
    const navigate = useNavigate()
    const location = useLocation()
    const [pendingIDC, setPendingIDC] = useState([])
    const [totalPendingIDC, setTotalPendingIDC] = useState(0)
    const [pagination, setPagination] = useState({ page: 1, limit: 10 })
    const [filters, setFilters] = useState([])
    const [projectOptions, setProjectOptions] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedPendingReview, setSelectedPendingReview] = useState({});
    const [editPendingReviewerChangeModal, setPendingReviewerChangeModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [projectId, setProjectId] = useState("");
    const [docNumber, setDocNumber] = useState("");
    const { updateCurrentPage } = useCurrentPage();
    const params = new URLSearchParams(location.search);
    const changedParams = Object.fromEntries(params);
    const {deBounce}=TimingFunctions()
    const { user } = useAuth();
    const allowedRoles = ["admin", "superadmin"];
    useEffect(() => {
        updateCurrentPage({
            title: "Pending Reviews",

        });
        getAllEmployees();
    }, [])

    useEffect(() => {
        let filters = paramsToFilters(changedParams?.filters, "filters");
        let filtersObj = paramsToFiltersObj(changedParams?.filters);

        setFilters(filtersObj);

        setPagination({
            page: changedParams?.page || 1,
            limit: changedParams?.limit || 10,
        });
        getEventWorkflowList({ page: changedParams?.page||1, limit: changedParams?.limit||10, filters });
        getProjectOptions();
    }, [changedParams?.page,
    changedParams?.limit,
    changedParams?.filters,
    changedParams?.sorting])

    useEffect(() => {
        getEventWorkflowList({
            page: changedParams?.page || 1,
            limit: changedParams.limit || 50
        });
    }, [projectId, docNumber]);

    const getEventWorkflowList = async ({ page, limit, filters }) => {
        if(filters && Array.isArray(filters)){
            filters=[...filters, {key:"workflowStatus",eq:"pending"}]
        }else{
            filters=  [{key:"workflowStatus",eq:"pending"}]
        }
      
        const details = {
            pageSize: limit || 10,
            pageNumber: page || 1,
            filters,
            sorting: [{ key: "created_at", direction: "desc" }],
        };
        if (projectId && projectId !== "") details.projectId = projectId;
        if (docNumber && docNumber !== "") details.searchString = docNumber;
        await axios
            .all([getDocumentWorkflowsByUser(details)])
            .then(res => {
                if (res[0]) {
                    let resData = res[0].data ? res[0].data : [];
                    let totalCount = res[0].totalCount ? res[0].totalCount : 0;
                    resData = resData?.map((each, index) => {
                        return {
                            ...each,
                            sNo: index + 1 + (page - 1) * limit
                        }
                    })
                
                    setPendingIDC(resData);
                    setTotalPendingIDC(totalCount)
                }
            })
    }

    const getProjectOptions = async (inputValue) => {
        const details = {
          pageSize: 50,
          pageNumber: 1,
          ...(inputValue
            ? {
                filters: [
                  { key: "name", iLike: inputValue },
                  { key: "status", eq: "active" },
                ],
              }
            : { filters: [{ key: "status", eq: "active" }] }),
          sorting: [{ key: "created_at", direction: "desc" }],
        };
        let options = await axios
          .all([getProjectMasters(details)])
          .then(function (res) {
            if (res[0]) {
              let resData = res[0];
              if (resData.success) {
                let projects = resData.data ? resData.data : [];
                projects = projects.map((_, index) => {
                  return {
                    ..._,
                    label: _.name,
                    value: _.publicId,
                  };
                });
                projects.unshift({ label: "All", value: "" });
                setProjectOptions(projects);
                return projects;
              }
            }
          })
          .catch(function (res) {
            if (res.message) console.log("An error occurred in getting Projects");
            return [];
          });
        return options;
      };
    
      const loadDebounceOptions = (inputValue, callback) => {
        deBounce(() => loadOptions(inputValue, callback), 1000);
      };
    
      const loadOptions = async (inputValue, callback) => {
        if (!inputValue) {
          callback(projectOptions);
        }
    
        let options = [];
    
        options = await getProjectOptions(inputValue);
    
        callback(options);
      };

      const loadEmployeeDebounceOptions = (inputValue, callback) => {
        deBounce(() => loadEmployeeOptions(inputValue, callback), 1000);
      };
    
      const loadEmployeeOptions = async (inputValue, callback) => {
        if (!inputValue) {
          callback(projectOptions);
        }
    
        let options = [];
    
        options = await getProjectOptions(inputValue);
    
        callback(options);
      };

    const onPage = (page) => {
        let mfilters = filtersToUri(filters);
        let search = filtersToUrlParams({
            page,
            limit: pagination.limit,
            filters: mfilters,

        });
        navigate({ search });
    };

    const searchPendingIDC = (e) => {
        // deBounce(() => onSearchFilter(e), 1000);
        const value = (e.target.value).trim();
        deBounce(() => setDocNumber(value), 500);
      };
    
      const onSearchFilter = (e) => {
        let mfilters = filtersToUri({
          ...filters,
          name: { name: e.target.value },
        });
        let search = filtersToUrlParams({
          page: 1,
          limit: 10,
          filters: mfilters,
        });
        navigate({
          search,
        });
      };

      const onPendingReviewerChange = (selected) => {
        if (selected) {
          setSelectedPendingReview(selected);
        } else {
          setSelectedPendingReview("");
        }
        setPendingReviewerChangeModal(!editPendingReviewerChangeModal);
      };
    
      const getAllEmployees = async (e, search) => {
        const details = {
          pageSize: 100,
          pageNumber: 1,
          ...(e && e.value !== "all"
            ? {
                filters: [{ key: "deptId", eq: e.publicId }],
              }
            : {}),
          ...(search && { filters: [{ key: "empName", iLike: search }] }),
        };
    
        const emps = await getEmployeesMaster(details)
          .then((res) => {
            if (res.success) {
              let resData = res.data.map((each) => {
                return {
                  ...each,
                  label: each.empName,
                  value: each.publicId,
                };
              });
    
              !search && setEmployees(resData);
              return resData;
            }
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
        return emps;
      };

    return (
        <div className="pendingReview-main">
            <div className="heading">
                <div>
                <span><img src={ArrowBack} onClick={() => navigate(-1)} /></span>
                <span style={{ fontWeight: '700', fontSize: '20px' }}>
                    {' '}Pending IDC ({`${totalPendingIDC}`})</span>
                    </div>
                <InputGroup style={{ width: "auto" }}>
                    <Form.Control
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search"
                        className="search-input-main"
                        onChange={(e) => searchPendingIDC(e)}
                    />
                    <InputGroup.Text id="search" className="search-input-main">
                        <img src={searchicon} alt="search icon" />
                    </InputGroup.Text>
                </InputGroup>
            </div>
            <div className="d-flex justify-content-end my-3">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <AsyncSelect
                        className="sop-custom-form-select"
                        id="ProjectId"
                        placeholder="Select Project "
                        onChange={(e) => setProjectId(e?.publicId)}
                        loadOptions={(input, callback) =>
                            loadDebounceOptions(input, callback)
                        }
                        defaultOptions={projectOptions}
                        cacheOptions
                    />
                </div>
            </div>
            <div className="pending-table-main mb-3">
                {pendingIDC && pendingIDC.length>0 ?
                    <Table bordered responsive className="bordered table-bordered">
                        <thead>
                            <tr>
                            <th>S.No.</th>
                                <th>Document Number</th>
                                <th>Document Name</th>
                                <th>Project Name</th>
                                <th>User</th>
                                <th style={{width: "100px"}}>Date</th>
                                <th>Document Status</th>
                               

                            </tr>
                        </thead>
                        <tbody>
                            {pendingIDC && pendingIDC.length > 0 &&
                                pendingIDC?.map(each => (
                                    <tr>
                                        <td>{each?.sNo}</td>
                                        <td
                                            style={{ cursor: 'pointer', color: '#4682B4' }}
                                            onClick={() => {
                                                navigate({
                                                    pathname: '/documents/document-details',
                                                    search: `?documentId=${each?.document?.publicId}`
                                                })
                                            }}>{each?.document?.documentNumber}</td>
                                        <td>{each?.document?.documentName}</td>
                                        <td>{each?.document?.project?.name}</td>
                                        <td>
                                        {each?.user?.empEmail}
                                        {/* {(user?.user_id === each?.document?.project?.projectEngineer || allowedRoles.includes(user.role)) && (
                                            <div>
                                                <img
                                                    src={editicon}
                                                    alt="Edit icon"
                                                    style={{ cursor: "pointer" }}
                                                    data-tooltip-id="editTooltip"
                                                    data-tooltip-content="Edit Review User"
                                                    onClick={() => onPendingReviewerChange(each)}
                                                />
                                                <Tooltip id="editTooltip" place="top" effect="solid" />
                                            </div>
                                        )} */}
                                        </td>
                                        <td>{each?.createdAt ? moment(each?.createdAt).format("DD-MM-YYYY") : ""}</td>
                                        <td>{each?.document?.documentStatus}</td>
                                      
                                    </tr>
                                )

                                )}
                        </tbody>
                    </Table>
                    :
                    <div>NO Pending IDC Found</div>
                }

            </div>
            {pendingIDC &&
                <div class="custom-pagination-main">
                    <div class="card-inner">
                        <Pagination
                            totalCount={totalPendingIDC}
                            currentPage={pagination.page}
                            rowsPerPage={pagination.limit}
                            onNext={onPage}
                            onPrev={onPage}
                            onPageClick={onPage}

                        />
                    </div>
                </div>
            }

            <Modal
                centered
                show={editPendingReviewerChangeModal}
                onHide={onPendingReviewerChange}
                className="add-department-modal-main"
            >
                <Modal.Header closeButton className="add-department-modal-header">
                    <Modal.Title className="add-department-modal-title">
                        Update Pending IDC Reviewer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-department-modal-body">
                    <Formik
                        initialValues={{
                            pendingReviewer: selectedPendingReview?.user ? {
                                ...selectedPendingReview?.user,
                                label: selectedPendingReview?.user?.empName,
                                value: selectedPendingReview?.user?.publicId,
                            } : {},
                            docNumber: selectedPendingReview?.document?.documentNumber || "",
                            docStatus: selectedPendingReview?.document?.documentStatus || "",
                            projName: selectedPendingReview?.document?.project?.name || "",
                        }}
                        onSubmit={(values) => {
                            // updateDdrDeliverableCode(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <Form className="form" onSubmit={handleSubmit}>
                                <div className="row d-flex gap-3">
                                    <div className="form-group col-12">
                                        <label class="form-label" for="projName">
                                            Project Name
                                        </label>
                                        <div class="form-control-wrap">
                                            <PrimaryInput
                                                placeholder="Document Number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.projName}
                                                name="projName"
                                                id="projName"
                                                type="text"
                                                disabled
                                                style={{
                                                    backgroundColor: "#e9ecef", 
                                                    fontWeight: "bold" 
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-12">
                                        <label class="form-label" for="docNumber">
                                            Document Number
                                        </label>
                                        <div class="form-control-wrap">
                                            <PrimaryInput
                                                placeholder="Document Number"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.docNumber}
                                                name="docNumber"
                                                id="docNumber"
                                                type="text"
                                                disabled
                                                style={{
                                                    backgroundColor: "#e9ecef", 
                                                    fontWeight: "bold" 
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-12">
                                        <label class="form-label" for="docStatus">
                                            Document Status
                                        </label>
                                        <div class="form-control-wrap">
                                            <PrimaryInput
                                                placeholder="Document Status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.docStatus}
                                                name="docStatus"
                                                id="docStatus"
                                                type="text"
                                                disabled
                                                style={{
                                                    backgroundColor: "#e9ecef", 
                                                    fontWeight: "bold" 
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-12">
                                        <label class="form-label" for="pendingReviewer">
                                            User
                                        </label>
                                        <AsyncSelect
                                            className="sop-custom-form-select"
                                            id="pendingReviewer"
                                            name="pendingReviewer"
                                            placeholder="Select Employee "
                                            onChange={(e) => setFieldValue("pendingReviewer", e)}
                                            value={values?.pendingReviewer}
                                            onBlur={handleBlur}
                                            loadOptions={loadEmployeeDebounceOptions}
                                            defaultOptions={employees}
                                            cacheOptions
                                        />
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div className="d-flex gap-2">
                                        <Button
                                            className="back-icon-btn-main"
                                            onClick={onPendingReviewerChange}
                                        >
                                            <span>{squareBackIcon}</span>
                                            <span className="ms-2">Back</span>
                                        </Button>
                                        <Button className="theme-btn-main" type="submit">
                                            {buttonLoading ? (
                                                <ButtonLoading />
                                            ) : "Update"}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    )
}