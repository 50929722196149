import React, {  useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";
import AuthLayout from "../layouts/AuthLayout";
import Layout from "../layouts/Layout";
import * as Storage from "../../store/LocalStorage";
import { LazyLoader } from "../../assets/loaders";
import Login from "../containers/login";
import PermissionRoute from "./PermissionRoute";
import axios from "axios";
import MainServices from "../../services/MainServices";
import usePermission from "../../hooks/usePermission";
import PageNotFound from "../containers/pageNotFound";
import DocumentDashboard from "../containers/documentDashboard";
import Projects from "../containers/projects";
import AddProject from "../containers/projects/AddProject";
import ProjectDetails from "../containers/projects/ProjectDetails";
import ResourcePools from "../containers/resourcePools";
import CheckLists from "../containers/checkLists";

import { notifySuccess, notifyWarn } from "../../assets/elements/Toast";
import ForgetPassword from "../containers/forgetPassword";
import ResetPassword from "../containers/resetPassword/ResetPassword";
import CTRList from "../containers/CTR/CTRList";
import CTRDeliverable from "../containers/CTR/CTRDeliverable";
import Documents from "../containers/documents";
import AddDocument from "../containers/documents/AddDocument";
import IDCMatrix from "../containers/IDCMatrix/IDCMatrix";
import AddIDCMatrix from "../containers/IDCMatrix/AddIDCMatrix";
import DocumentList from "../containers/documents/DocumentsList";
import DoctypeMaster from "../containers/doctypes";
import CheckListMasters from "../containers/checkLists";
import CheckListDetails from "../containers/checkLists/CheckListsDetails";
import DocumentDetails from "../containers/documents/details";
import Repository from "../containers/projects/Repository";
import IDCMatrixDetails from "../containers/IDCMatrix/IDCMatrixDetails";
import Category from "../containers/category";
import DocumentWorkflow from "../containers/documents/DocumentWorkflow";
import ApproverDashboard from "../containers/approverDashboard";
import ReviewedDocuments from '../containers/reviewedDashboard';
import IDCCompletedDocuments from '../containers/IDCCompletedDocuments';
import DocumentDdrList from "../containers/documents/DocumentsDdrList";
import PLIPList from "../containers/PLIP/PLIPList";
import ProjectSummary from "../containers/projects/ProjectSummary";
import TPSList from "../containers/tps/TPSList";
import CTRActivity from "../containers/CTRActivity/CTRActivity";
import ProjectCTRActivity from "../containers/projectCTRActivity/ProjectCTRActivity";
import ResourceCost from "../containers/resourceCost";
import MasterCTD from "../containers/masterCTD";
import ProjectTPS from "../containers/projectTPS";
import JobCircular from "../containers/jobCircular";
import ProjectCTD from '../containers/projectCTD'
import PendingReview from "../containers/documentDashboard/PendingReviews";
import IDCPending from "../containers/documentDashboard/IDCPendings";
import NewRepository from '../containers/projects/NewRepository'
import FolderPermissions from "../containers/folderPermissions";

import Idc from "../containers/masters/idc/Idc"
import IdcDetails from "../containers/masters/idc/Details"

export default function CustomRoutes() {
  const { user, updateUserContext } = useAuth();
  const { updatePermissions } = usePermission();
  const [loading, setLoading] = useState(false);
  const config = useConfig();
  const navigate = useNavigate();
  const { getRolePermissionsByRoles, getUserDetails } = MainServices();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    if (token) {
      getUser(token);
    } else {
     checkSession();
    }
    // eslint-disable-next-line
  }, []);

  const getUser = async () => {
    setLoading(true);
    const details = {};
    await axios
      .all([getUserDetails(details, token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let userDetails = resData?.data || {};
            userDetails = {
              token,
              email: userDetails?.empEmail,
              name: userDetails?.empName,
              role: userDetails?.userRole?.role?.role,
              user_id: userDetails?.publicId,
            };
            Storage.set(config.sessionKey, JSON.stringify(userDetails));
            updateUserContext(userDetails);
            getRolePermissions(token);
            navigate("/dashboard");
            notifySuccess("User Login Successfully");
            setLoading(true);
          } else {
            notifyWarn(message);
            setLoading(true);
          }
        } else {
          setLoading(true);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in User Signin");
      });
  };

  const checkSession = () => {
    setLoading(true);
    let session = Storage.get(config.sessionKey);
    if (session) {
      session = JSON.parse(session);
      getRolePermissions(session.token);
      updateUserContext(session);
    }
     else {
      if(!config.local){

        window.location.href =
        config.loginUrl + "?redirectUri=" + config.projectUrl;
      } else{
        navigate('/login')
      }
      setLoading(false);
    }
  };

  //api
  const getRolePermissions = async (token) => {
    const details = {
     source: "galfar",
    };
    await axios
      .all([getRolePermissionsByRoles(details, token)])
      .then(async function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let rolePermissions = resData.data ? resData.data : [];
            rolePermissions = rolePermissions.map(
              (each) => each.permissionname
            );
            await updatePermissions(rolePermissions);
            setLoading(false);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Permissions");
      });
  };

  return loading ? (
    <LazyLoader />
  ) : user?.token ? (
    <AuthLayout>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PermissionRoute
              component={DocumentDashboard}
              required_permission="ProjectDashboard_read"
            />
          }
        />
        
        <Route
          path="/dashboard/pending-reviews"
          element={
            <PermissionRoute
              component={PendingReview}
              required_permission="ReviewDashboard_read"
            />
          }
        />
        <Route
          path="/dashboard/idc-pending"
          element={
            <PermissionRoute
              component={IDCPending}
              required_permission="ReviewDashboard_read"
            />
          }
        />
        <Route
          path="/approver-dashboard"
          element={
            <PermissionRoute
              component={ApproverDashboard}
              required_permission="ProjectDashboard_read"
            />
          }
        />
      
        <Route
          path="/reviewed-documents"
          element={
            <PermissionRoute
              component={ReviewedDocuments}
              required_permission="ProjectDashboard_read"
            />
          }
        />
       

        <Route
          path="/idc-completed-documents"
          element={
            <PermissionRoute
              component={IDCCompletedDocuments}
              required_permission="ProjectDashboard_read"
            />
          }
        />
        <Route
          path="/documents/list"
          element={
            <PermissionRoute
              component={Documents}
              required_permission="Document_read"
            />
          }
        />
         <Route
          path="/masters/cost-time-deliverable"
          element={
            <PermissionRoute
              component={MasterCTD}
              required_permission="CostTimeDeliverable_read"
            />
          }
        />
        <Route
          path="/documents/add-document"
          element={
            <PermissionRoute
              component={AddDocument}
              required_permission="Document_write"
            />
          }
        />
        <Route
          path="/documents/document-details"
          element={
            <PermissionRoute
              component={DocumentDetails}
              required_permission="Document_read"
            />
          }
        />
        <Route
          path="/masters/ctr-list"
          element={
            <PermissionRoute
              component={CTRList}
              required_permission="MasterCTR_read"
            />
          }
        />
        <Route
          path="/masters/idc-matrix"
          element={
            <PermissionRoute
              component={Idc}
              required_permission="Document_read"
            />
          }
        />
        <Route
          path="/masters/idc-details"
          element={
            <PermissionRoute
              component={IdcDetails}
              required_permission="Document_read"
            />
          }
        />
         <Route
          path="/masters/resource-cost"
          element={
            <PermissionRoute
              component={ResourceCost}
              required_permission="ResourceCost_read"
            />
          }
        />
         <Route
          path="/masters/ctr-activity"
          element={
            <PermissionRoute
              component={CTRActivity}
              required_permission="MasterCtrActivity_read"
            />
          }
        />

        <Route
          path="/masters/plip-list"
          element={
            <PermissionRoute
              component={PLIPList}
              required_permission="PLIP_read"
            />
          }
        />

        <Route
          path="/masters/document-category"
          element={
            <PermissionRoute
              component={Category}
              required_permission="DocumentCategory_read"
            />
          }
        />

        <Route
          path="/masters/doctypes"
          element={
            <PermissionRoute
              component={DoctypeMaster}
              required_permission="DocumentType_read"
            />
          }
        />
        <Route
          path="/masters/check-list"
          element={
            <PermissionRoute
              component={CheckListMasters}
              required_permission="CheckListMaster_read"
            />
          }
        />
          <Route
          path="/masters/tps"
          element={
            <PermissionRoute
              component={TPSList}
              required_permission="MasterTPS_read"
            />
          }
        />
        <Route
          path="/masters/check-list/check-list-details"
          element={
            <PermissionRoute
              component={CheckListDetails}
              required_permission="CheckListMaster_read"
            />
          }
        />
        <Route
          path="/masters/workflow"
          element={
            <PermissionRoute
              component={IDCMatrix}
              required_permission="Document_read"
            />
          }
        />
        <Route
          path="/documents/add-workflow"
          element={
            <PermissionRoute
              component={AddIDCMatrix}
              required_permission="Document_write"
            />
          }
        />
        <Route
          path="/masters/workflow/workflow-details"
          element={
            <PermissionRoute
              component={IDCMatrixDetails}
              required_permission="Document_read"
            />
          }
        />
        <Route
          path="/masters/folder-access"
          element={
            <PermissionRoute
              component={FolderPermissions}
              required_permission="Document_read"
            />
          }
        />
        <Route
          path="/documents/document-list"
          element={
            <PermissionRoute
              component={DocumentList}
              required_permission="Document_read"
            />
          }
        />
        <Route
          path="/documents/document-ddr-list"
          element={
            <PermissionRoute
              component={DocumentDdrList}
              required_permission="Document_read"
            />
          }
        />
        <Route
          path="/documents/add-idc"
          element={
            <PermissionRoute
              component={DocumentWorkflow}
              required_permission="Document_read"
            />
          }
        />
        {/* <Route
          path="/documents/repository"
          element={
            <PermissionRoute
              component={Repository}
              required_permission="Document_read"
            />
          }
        /> */}
         <Route
          path="/documents/repository"
          element={
            <PermissionRoute
              component={NewRepository}
              required_permission="Document_read"
            />
          }
        />

        <Route
          path="/ctr-deliverable"
          element={
            <PermissionRoute
              component={CTRDeliverable}
              required_permission="MasterCTR_read"
            />
          }
        />

        <Route
          path="/project-management/projects"
          element={
            <PermissionRoute
              component={Projects}
              required_permission="Project_read"
            />
          }
        />
        <Route
          path="/project-management/project-ctr-activity"
          element={
            <PermissionRoute
              component={ProjectCTRActivity}
              required_permission="ProjectActivity_read"
            />
          }
        />


        <Route
          path="/project-management/projects/add-project"
          element={
            <PermissionRoute
              component={AddProject}
              required_permission="Project_write"
            />
          }
        />
        <Route
          path="/project-management/projects/project-preview"
          element={
            <PermissionRoute
              component={ProjectDetails}
              required_permission="Project_read"
            />
          }
        />
         <Route
          path="/project-management/projects/project-summary"
          element={
            <PermissionRoute
              component={ProjectSummary}
              required_permission="Project_read"
            />
          }
        />
         <Route
          path="/project-management/projects/project-tps"
          element={
            <PermissionRoute
              component={ProjectTPS}
              required_permission="ProjectTps_read"
            />
          }
        />
          <Route
          path="/project-management/projects/job-circular"
          element={
            <PermissionRoute
              component={JobCircular}
              required_permission="Project_read"
            />
          }
        />
         <Route
          path="/project-management/projects/project-ctd"
          element={
            <PermissionRoute
              component={ProjectCTD}
              required_permission="ProjectCtd_read"
            />
          }
        />
        <Route
          path="/masters/resource-pools"
          element={
            <PermissionRoute
              component={ResourcePools}
              required_permission="ResourcePool_read"
            />
          }
        />

        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="*"
          element={
            <PermissionRoute
              component={DocumentDashboard}
              required_permission="ProjectDashboard_read"
            />
          }
        />
      </Routes>
    </AuthLayout>
  ) : (
    <Layout>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
      </Routes>
    </Layout>
  );
}
